import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '@env/environment';
import { Apollo } from 'apollo-angular';
import { throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { Product } from 'wilco-lib-models';
import { ProductChild } from '../models';
import { Promotion } from '../models/promotion.model';
import {
  GET_QUEUE_COUNTS,
  GET_WOOSYNC_JOB,
  GetQueueCounts,
  PURGE_QUEUES,
  PurgeQueues,
  RESET_WOOSYNC_JOBS,
  UPDATE_VISIBLE,
  WoosyncJobQuery,
} from '../queries/woosync.graphql';
import { LoggerService } from './logger.service';

interface ProductQueueMessage {
  groupId: number;
  skus?: string[];
  debug: boolean;
}

interface PromotionQueueMessage {
  promotion: number;
  debug: boolean;
}

interface SyncAllQueueMessage {
  all: boolean;
}

type QueueMessage =
  | ProductQueueMessage
  | PromotionQueueMessage
  | SyncAllQueueMessage;

// just holding onto this for reference, this is what the previous sendToQueue was
// type PreviousProduct = {
//   groupId?: number;
//   skus?: string[];
//   promotion?: number;
//   all?: boolean;
//   debugGroupIds?: number[];
// };

@Injectable({
  providedIn: 'root',
})
export class WoosyncService {
  constructor(
    private logger: LoggerService,
    private http: HttpClient,
    private apollo: Apollo,
    private snackbar: MatSnackBar
  ) {}

  getWoosyncJob(id: number) {
    return this.apollo
      .watchQuery<WoosyncJobQuery>({
        query: GET_WOOSYNC_JOB,
        variables: {
          groupId: id,
        },
        pollInterval: 2000,
      })
      .valueChanges.pipe(map((result) => result.data.woosync));
  }

  resetWoosyncJobs() {
    return this.apollo.mutate({
      mutation: RESET_WOOSYNC_JOBS,
    });
  }

  syncProduct(product: Product, debug: boolean = false) {
    this.logger.debug(
      'WooSyncService',
      `Sending product group with ID ${product.id} to WooSync`
    );

    let productDTO: any = { groupId: product.id, debug: debug };

    if (product.items) {
      productDTO = { ...productDTO, skus: product?.items?.map((c) => c.id) };
    }

    return this.sendToQueue(productDTO)
      .then(() =>
        this.snackbar.open('Product sent to sync with Ecom', 'Dismiss', {
          duration: 1000,
        })
      )
      .catch(() =>
        this.snackbar.open('Failed to sync product with Ecom', 'Dismiss', {
          panelClass: 'bg-danger',
          duration: 2000,
        })
      );
  }

  syncProductChild(productChild: ProductChild, debug: boolean = false) {
    this.logger.debug(
      'WooSyncService',
      `Sending product item with SKU ${productChild.itemNumber} to WooSync`
    );
    const child = {
      groupId: productChild.groupId,
      skus: [productChild.itemNumber],
      debug: debug,
    };
    this.sendToQueue(child);
  }

  syncAllProductGroups() {
    this.logger.debug(
      'WooSyncService',
      'Requesting WooSync to sync all product groups'
    );
    return this.sendToQueue({
      all: true,
    });
  }

  syncPromotion(promotion: Promotion, debug: boolean = false) {
    this.logger.debug(
      'WooSyncService',
      `Sending promotion with ID ${promotion.id} to WooSync`
    );
    return this.sendToQueue({
      promotion: promotion.id,
      debug: debug,
    });
  }

  unpublishProductChild(productChild: ProductChild) {
    return new Promise<void>(async (resolve, reject) => {
      this.logger.debug(
        'WooSyncService',
        `Unpublishing item with SKU ${productChild.itemNumber}`
      );

      await this.updateVisible(productChild.itemNumber, 'No');

      this.sendToQueue({
        groupId: productChild.groupId,
        skus: [productChild.itemNumber],
        debug: false,
      });

      resolve();
    });
  }

  publishProductChild(productChild: ProductChild) {
    return new Promise<void>(async (resolve, reject) => {
      this.logger.debug(
        'WooSyncService',
        `Publishing item with SKU ${productChild.itemNumber}`
      );

      await this.updateVisible(productChild.itemNumber, 'Yes');

      this.sendToQueue({
        groupId: productChild.groupId,
        skus: [productChild.itemNumber],
        debug: false,
      });

      resolve();
    });
  }

  getQueueCounts() {
    return this.apollo
      .use('omniApi')
      .watchQuery<GetQueueCounts>({
        query: GET_QUEUE_COUNTS,
        fetchPolicy: 'network-only',
        pollInterval: 2000,
      })
      .valueChanges.pipe(map((result) => result.data.getQueueCounts));
  }

  purgeQueues(queues: any[]) {
    return this.apollo
      .use('omniApi')
      .mutate<PurgeQueues>({
        mutation: PURGE_QUEUES,
        variables: { payload: { queues } },
      })
      .pipe(map((result) => result.data.purgeQueues));
  }

  private updateVisible(itemNumber: string, visible: string) {
    return this.apollo
      .mutate({
        mutation: UPDATE_VISIBLE,
        variables: {
          itemNumber,
          visible,
        },
      })
      .toPromise();
  }

  private sendToQueue(product: QueueMessage) {
    return new Promise((resolve, reject) => {
      const options: any = { responseType: 'text' };
      if (environment.name === 'development' || environment.name === 'local') {
        options.rejectUnauthorized = false;
      }

      this.http
        .post(environment.woosyncEndpoint, [product], options)
        .pipe(
          take(1),
          catchError((err) => {
            this.logger.error(
              'WoosyncService',
              'Failed to send to WooSync Queue',
              err
            );
            return throwError('Failed to send to WooSync Queue');
          })
        )
        .subscribe(
          (resp) => {
            this.logger.info('WoosyncService', resp);
            resolve(resp);
          },
          (error) => reject(error)
        );
    });
  }
}
