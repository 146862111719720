export const environment = {
  production: false,
  name: 'staging',
  host: 'https://back40.farmstore-staging.com',
  graphqlEndpoint: 'https://api.farmstore-staging.com/graphql',
  graphqlEndpointV2: 'https://api.farmstore-staging.com/v2/graphql',
  corsEndpoint: 'https://api.farmstore-staging.com/v2/cors',
  websocketEndpoint: 'wss://7qljzec1bl.execute-api.us-west-2.amazonaws.com/dev',
  ecomURL: 'https://farmstore-staging.com',
  wilcoApiKey: '8tPnThZWUEgNqub2BRyywyedUmtceCWihuXt',
  woosyncEndpoint:
    'https://ukvqr4evjj.execute-api.us-west-2.amazonaws.com/stg/sync',
  mediaURL: 'https://media.back40.cloud/',
  woosyncProcessorQueue: 'deprecated',
  woosyncWorkerQueue: 'deprecated',
  awsAccessKey: 'deprecated',
  awsSecretKey: 'deprecated',
  s3MediaEndpoint: 'https://media.back40.cloud/',
  sentryDsn: 'https://07464cc75e4fca0008d52243254f29a1@o4508140441370624.ingest.us.sentry.io/4508140444909568',
};
