import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@env/environment';
import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';

declare const built_release_name: string;

const release_name = built_release_name || '';

Sentry.init({
  dsn: environment.sentryDsn,
  environment: environment.name,
  release: release_name,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/back40\.cloud\//,
    /^https:\/\/back40\.farmstore\.com\.k8s\//,
    /^https:\/\/back40\.farmstore-testing\.com\.k8s\//,
    /^https:\/\/back40\.farmstore-staging\.com\.k8s\//,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeSend(event, hint) {
    const includedEnvironments = ['testing', 'staging', 'production'];
    if (!includedEnvironments.includes(environment.name.toLowerCase())) {
      return null;
    }
    return event; // Send the event if not in local
  },
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
