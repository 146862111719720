import gql from 'graphql-tag';
import { ProductCategory } from '../models';

export const GET_PRODUCT_CATEGORIES_GROUPED = gql`
  {
    productCategories(grouped: true) {
      id
      name
      position
      finelines {
        fineline
      }
      children {
        id
        name
        position
        finelines {
          fineline
        }
        children {
          id
          name
          position
          finelines {
            fineline
          }
        }
      }
    }
  }
`;

const CATEGORY_FIELDS = `
  id
    name
    status
    parentId
    image
    wooId
    isFeatured
    startDate
    endDate
    googleTaxonomy
    brands {
      id
      name
    }
    finelines {
      fineline
      deliveryAvailable
      requiresFraudCheck
      showOnKiosk
      taxCode
      products {
        childId
        itemNumber
        parent {
          groupId
          title
        }
      }
    }
    parent {
      wooId
    }
    children {
      id
    }
`;

export const GET_PRODUCT_CATEGORY = gql`
  query ProductCategory($id: Int) {
    productCategory(id: $id) {
      ${CATEGORY_FIELDS}
    }
  }
`;

export const UPDATE_PRODUCT_CATEGORY = gql`
  mutation UpdateProductCategory($productCategory: ProductCategoryInput) {
    updateProductCategory(productCategory: $productCategory) {
      ${CATEGORY_FIELDS}
    }
  }
`;

export const DELETE_PRODUCT_CATEGORY = gql`
  mutation DeleteProductCategory($id: Int) {
    deleteProductCategory(id: $id)
  }
`;

export interface ProductCategoriesQuery {
  productCategories: ProductCategory[];
}

export interface ProductCategoryQuery {
  productCategory: ProductCategory;
}
