import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { S3ImageService } from '@app/core/services/content/s3-image.service';
import { environment } from '@env/environment';
import { Apollo } from 'apollo-angular';
import { interval, Observable, of, Subject } from 'rxjs';
import { map, startWith, switchMap, takeWhile, tap } from 'rxjs/operators';
import { Fulfillment, Package, Period, Refund } from 'wilco-lib-models';
import { StoreUserType } from 'wilco-lib-models/src/domain/stores';
import { AuthService } from '../auth/auth.service';
import * as f from '../queries/order/fulfillments.graphql';
import * as p from '../queries/order/packages.graphql';
import { DELETE_REFUND } from '../queries/order/refund.graphql';
import { APIConnector } from './api.connector';

@Injectable({
  providedIn: 'root',
})
export class OrderFulfillmentService {
  private readonly _packagesUpdated$ = new Subject<void>();
  readonly packagesUpdated$ = this._packagesUpdated$
    .asObservable()
    .pipe(startWith([]));

  constructor(
    private apollo: Apollo,
    private connector: APIConnector,
    private authService: AuthService,
    private imageService: S3ImageService,
    private snackbar: MatSnackBar
  ) {}

  pickers(storeNumber: number) {
    return storeNumber
      ? this.connector
          .query<f.IGetPickers>({
            query: f.GET_PICKERS,
            variables: {
              storeNumber,
            },
          })
          .pipe(map((results) => results.data.pickers))
      : of([]);
  }

  getAuditors(storeNumber: number) {
    return storeNumber
      ? this.connector
          .query<f.IGetAuditors>({
            query: f.GET_AUDITORS,
            fetchPolicy: 'network-only',
            variables: {
              storeNumber,
            },
          })
          .pipe(map((results) => results.data.getAuditors))
      : of([]);
  }

  getPackageId({ packageNumber = null }) {
    return this.connector
      .query<p.IGetPackage>({
        query: p.GET_PACKAGE_ID,
        variables: { packageNumber },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((result) => result.data.package),
        map((result) => (result ? new Package(result) : result))
      );
  }

  package({ id = null, packageNumber = null, watch = false }) {
    const query = watch
      ? this.authService.state$.pipe(
          switchMap(({ storeId: storeNumber }) =>
            this.connector.watchQuery<p.IGetPackage>({
              query: p.GET_PACKAGE_BY_ID,
              variables: { id, packageNumber, storeNumber },
              fetchPolicy: 'network-only',
              pollInterval: 2000,
            })
          )
        )
      : this.authService.state$.pipe(
          switchMap(({ storeId: storeNumber }) =>
            this.connector.query<p.IGetPackage>({
              query: p.GET_PACKAGE_BY_ID,
              variables: { id, packageNumber, storeNumber },
              fetchPolicy: 'network-only',
            })
          )
        );

    return query.pipe(
      map((result) => result.data.package),
      map((result) => (result ? new Package(result) : result))
    );
  }

  packages(params: p.IGetPackageParams, lite = true) {
    const query = lite ? p.GET_PACKAGES_BY_STORE_LITE : p.GET_PACKAGES_BY_STORE;

    return this.connector
      .watchQuery<p.IGetPackages>({
        query,
        variables: { params: { ...params, limit: 100 } },
        fetchPolicy: 'network-only',
        pollInterval: 5000,
      })
      .pipe(map((result) => result.data.packages.map((p) => new Package(p))));
  }

  missionControlPackages(params: p.IGetPackageParams) {
    return this.connector
      .watchQuery<p.IGetPackagesForMC>({
        query: p.GET_PACKAGES_FOR_MC,
        variables: { params: { ...params, limit: 100 } },
        fetchPolicy: 'network-only',
        pollInterval: 5000,
      })
      .pipe(
        map((result) =>
          result.data.missionControlPackages.map((p) => new Package(p))
        )
      );
  }

  getActionablePackages(limit = 10, offset = 0) {
    return this.authService.state$.pipe(
      switchMap((state) =>
        this.connector
          .watchQuery<p.IGetActionablePackages>({
            query: p.GET_ACTIONABLE_PACKAGES,
            fetchPolicy: 'network-only',
            pollInterval: 30000,
            variables: { storeNumber: state.storeId, limit, offset },
          })
          .pipe(
            map((result) => ({
              ...result.data.getActionablePackages,
              results: result.data.getActionablePackages.results.map(
                (p) => new Package(p)
              ),
            }))
          )
      )
    );
  }

  getFulfillmentMetrics(period = Period.DAILY) {
    return this.connector
      .watchQuery<f.IGetFulfillmentMetrics>({
        query: f.GET_FULFILLMENT_METRICS,
        fetchPolicy: 'network-only',
        pollInterval: 60000,
        variables: { period },
      })
      .pipe(map((result) => result.data.getFulfillmentMetrics));
  }

  /**
   * Updates one package. It is worth noting that this will bypass the
   * communications workflow (no emails will be sent out).
   */
  updatePackage(_package: Partial<Package>) {
    return this.apollo
      .use('omniApi')
      .mutate<any>({
        mutation: p.UPDATE_PACKAGE,
        variables: { _package },
      })
      .pipe(tap(() => this._packagesUpdated$.next()));
  }

  /**
   * Updates a stores picker/auditor. Adds them to their respective lists and updates
   * any stale values
   */
  updateStoreUsers(type: StoreUserType, name: string, storeNumber: number) {
    return this.apollo.use('omniApi').mutate<any>({
      mutation: p.UPDATE_STORE_USERS,
      variables: { type, name, storeNumber },
    });
  }

  updatePackages(packages: Partial<Package>[], noDelete = true) {
    return this.apollo
      .use('omniApi')
      .mutate<any>({
        mutation: p.UPDATE_PACKAGES,
        variables: {
          packages,
          noDelete,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updatePackages: 'done',
        },
      })
      .pipe(tap(() => this._packagesUpdated$.next()));
  }

  createShipment(payload: any) {
    return this.apollo.use('omniApi').mutate<any>({
      mutation: p.CREATE_SHIPMENT,
      variables: {
        payload,
      },
    });
  }

  createCustomerReceipt(id: number) {
    return this.imageService
      .getS3Bucket(`receipts/customer_receipt_${id}_censored.pdf`)
      .pipe(
        tap((links) => {
          if (links.length === 0) return;

          window.open(links[0], '_blank');
        }),
        switchMap((links) =>
          links.length === 0
            ? this.apollo
                .use('omniApi')
                .mutate<p.ICreateCustomerReceipt>({
                  mutation: p.CREATE_CUSTOMER_RECEIPT,
                  variables: { id },
                })
                .pipe(map((result) => result.data.createCustomerReceipt))
            : of(null)
        ),
        tap((result) => {
          if (!result) return;

          if (result === 'error') {
            this.snackbar.open(
              'Customer receipt could not be created',
              'CLOSE',
              {
                duration: 3000,
              }
            );
            return;
          }
          window.open(`${environment.s3MediaEndpoint}${result}`, '_blank');
        })
      );
  }

  /**
   * This query will fetch up to 3 fulfillments to reserve for the current picker.
   * If nothing is returned by the inital query, an interval is started. Every 2
   * seconds the query will be retried until fulfillments are returned from the
   * query. This query is garbage collected on navigation away from picker-details.
   *
   * @param store Store number to query fulfillments from
   * @returns
   */
  reserveFulfillments(picker: string): Observable<Fulfillment[]> {
    const query = this.authService.state$.pipe(
      switchMap((state) =>
        this.apollo
          .use('omniApi')
          .mutate<f.IReserveFulfillments>({
            mutation: f.RESERVE_FULFILLMENTS,
            variables: {
              store: state.storeId,
              picker,
            },
          })
          .pipe(
            map((results) =>
              results.data.reserveFulfillments.map((f) => new Fulfillment(f))
            )
          )
      )
    );

    return query.pipe(
      switchMap((f) => {
        if (f.length > 0) return of(f);

        return interval(2000).pipe(
          startWith(f),
          switchMap(() => query),
          takeWhile((value) => value.length === 0, true)
        );
      })
    );
  }

  getFulfillments() {
    const status = 'processing';

    return this.authService.state$.pipe(
      switchMap((state) =>
        this.connector
          .query<f.IGetFulfillments>({
            query: f.GET_FULFILLMENTS,
            variables: {
              params: {
                storeNumber: state.storeId,
                status,
              },
            },
            fetchPolicy: 'network-only',
          })
          .pipe(
            map((results) =>
              results.data.fulfillments.map((f) => new Fulfillment(f))
            )
          )
      )
    );
  }

  updateFulfillments(fulfillments: Partial<Fulfillment>[]) {
    return this.apollo
      .use('omniApi')
      .mutate<any>({
        mutation: f.UPDATE_FULFILLMENTS,
        variables: {
          fulfillments,
        },
      })
      .pipe(tap(() => this._packagesUpdated$.next()));
  }

  deleteRefund(refund: Refund) {
    return this.apollo
      .use('omniApi')
      .mutate<any>({
        mutation: DELETE_REFUND,
        variables: {
          refund,
        },
      })
      .pipe(
        tap(() =>
          this.snackbar.open('Refund has been deleted', 'CLOSE', {
            duration: 3000,
          })
        )
      );
  }
}
