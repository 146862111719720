import { HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ErrorHandler,
  Injectable,
  NgModule,
} from '@angular/core';
import { Router } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  ApolloLink,
  InMemoryCache,
  defaultDataIdFromObject,
  from,
} from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { CoreModule } from '@app/core';
import { AuthService } from '@app/core/auth';
import { environment } from '@env/environment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { APOLLO_NAMED_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { persistCache } from 'apollo-cache-persist';
import QueueLink from 'apollo-link-queue';
import { RetryLink } from 'apollo-link-retry';
import SerializingLink from 'apollo-link-serialize';
import { getMainDefinition } from 'apollo-utilities';
import { LocalStorageWrapper } from 'apollo3-cache-persist';
import {
  NgxZendeskWebwidgetConfig,
  NgxZendeskWebwidgetModule,
} from 'ngx-zendesk-webwidget';
import omitDeep from 'omit-deep-lodash';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  AppState,
  RootStoreModule,
  metaReducers,
} from './shared/stores/app-store.module';
import { TrackerLink } from './shared/stores/tracker-link/tracker-link';
import { ExceptionCaptureService } from '@app/core/services/exception-capture.service';
import * as Sentry from '@sentry/angular';

@Injectable({ providedIn: 'root' })
export class ZendeskConfig extends NgxZendeskWebwidgetConfig {
  accountUrl = 'wilco.zendesk.com';

  callback(zE) {}
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    HttpClientModule,
    ApolloModule,
    MatSnackBarModule,
    CoreModule,
    AppRoutingModule,
    FontAwesomeModule,
    RootStoreModule,
    StoreModule.forRoot({}, { metaReducers }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: true, // Restrict extension to log-only mode
      // autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    EffectsModule.forRoot([]),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      // registrationStrategy: 'registerWhenStable:30000',
      registrationStrategy: 'registerImmediately',
    }),
    NgxZendeskWebwidgetModule.forRoot(ZendeskConfig),
  ],
  providers: [
    AuthService,
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory(
        httpLink: HttpLink,
        store: Store<AppState>,
        snackBar: MatSnackBar
      ) {
        let hasError = false;
        const offlineLink = new QueueLink();
        const trackerLink = new TrackerLink(store, offlineLink);
        const serializingLink = new SerializingLink();
        const retryLink = new RetryLink({
          attempts: (count, operation, error) => {
            hasError = true;
            snackBar.open(
              'Unable to connect to the server at this time... Retrying ' +
                count,
              'CLOSE',
              {
                duration: 30000,
              }
            );

            return !!error;
          },
          delay: (count, operation, error) => {
            return count * (1000 * Math.random() + 300);
          },
        });
        const errorLink = onError(
          ({ response, graphQLErrors, networkError }) => {
            if (graphQLErrors) {
              console.error(
                'GraphQL Error',
                'A GraphQL request returned an error',
                graphQLErrors
              );

              graphQLErrors.forEach((error) => {
                Sentry.captureException(error);
              });

              if (!hasError) {
                snackBar.open(
                  'Something has gone wrong, try again or reload.',
                  'CLOSE',
                  { duration: 30000 }
                );
              }
            }
            if (networkError) {
              // we just output this to console, rather than logging an error
              // because if the client is offline, the log entry will fail to save as well causing a loop
              console.error(
                'GraphQL Network Error',
                'A network error was reported',
                networkError
              );
              Sentry.captureException(networkError);
            }
            hasError = true;
          }
        );

        const errorRecoverLink = new ApolloLink((operation, forward) => {
          if (hasError) {
            snackBar.dismiss();
            hasError = false;
          }
          return forward ? forward(operation) : null;
        });

        const removeTypenamesMiddleware = new ApolloLink(
          (operation, forward) => {
            const keysToOmit = ['__typename']; // more keys like timestamps could be included here

            const def = getMainDefinition(operation.query) as any;
            if (def && def.operation === 'mutation') {
              operation.variables = omitDeep(operation.variables, keysToOmit);
            }
            return forward ? forward(operation) : null;
          }
        );

        const responseMapLink = new ApolloLink((operation, forward) => {
          return forward(operation).map((response) => {
            return { ...response, operation: operation.operationName };
          });
        });

        const link = from([
          retryLink,
          errorLink,
          errorRecoverLink,
          removeTypenamesMiddleware,
          httpLink.create({
            uri: environment.graphqlEndpoint,
          }),
        ] as any);
        const linkV2 = from([
          responseMapLink,
          trackerLink,
          offlineLink,
          serializingLink,
          retryLink,
          errorLink,
          errorRecoverLink,
          removeTypenamesMiddleware,
          httpLink.create({
            uri: environment.graphqlEndpointV2,
          }),
        ] as any);

        const cache = new InMemoryCache({
          dataIdFromObject: (object: any) => {
            switch (object.__typename) {
              case 'ProductGroup':
                return `ProductGroup:${object.groupId}`;
              case 'ProductChild':
                return `ProductChild:${object.childId}`;
              default:
                return defaultDataIdFromObject(object);
            }
          },
        });

        window.addEventListener('offline', () => {
          console.log('Hit offline mode', offlineLink);
          offlineLink.close();
        });
        window.addEventListener('online', () => {
          console.log('Hit online mode', offlineLink);
          offlineLink.open();
        });

        persistCache({
          cache,
          storage: new LocalStorageWrapper(window.localStorage),
        });

        return {
          default: {
            cache: cache,
            link: link,
            defaultOptions: {
              watchQuery: {
                errorPolicy: 'all',
              },
            },
          },
          omniApi: {
            cache: cache,
            link: linkV2,
            defaultOptions: {
              watchQuery: {
                errorPolicy: 'all',
              },
            },
          },
        };
      },
      deps: [HttpLink, Store, MatSnackBar],
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
