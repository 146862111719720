import { AppState } from '@app/shared/stores/app-store.module';
import { getRouterParam } from '@app/shared/stores/router/actions/router.action';
import { createAction, createSelector } from '@ngrx/store';
import { Category } from 'wilco-lib-models';

// Selectors
export const selectCategories = createSelector(
  (state: AppState) => state.productStore,
  (productStore: AppState['productStore']) => productStore.categories
);

export const selectCategory = createSelector(
  (state: AppState) => state.productStore,
  (productStore: AppState['productStore']) => productStore.category
);

export const selectCategoryId = createSelector(
  getRouterParam('categoryId'),
  (id: string) => id
);

// Actions
export const fetchCategories = createAction('[Category] Fetch Categories');

export const getCategory = createAction(
  '[Category] Get Category',
  (category: Category) => ({ category })
);

export const getCategories = createAction(
  '[Category] Get Categories',
  (categories: Category[]) => ({ categories })
);

export const createCategory = createAction(
  '[Category] Creating Category',
  (category: Partial<Category>) => ({ category })
);

export const createCategorySuccess = createAction(
  '[Category] Category Created Successfully'
);

export const updateCategory = createAction(
  '[Category] Updating Category',
  (category: Partial<Category>) => ({ category })
);

export const updateCategorySuccess = createAction(
  '[Category] Category Updated Successfully'
);

export const updateCategories = createAction(
  '[Category] Updating Categories',
  (categories: Partial<Category>[]) => ({ categories })
);

export const updateCategoriesSuccess = createAction(
  '[Category] Categories Updated Successfully'
);

export const deleteCategory = createAction(
  '[Category] Deleting Category',
  (id: number) => ({ id })
);

export const deleteCategorySuccess = createAction(
  '[Category] Category Deleted Successfully'
);

export const syncCategoriesToEcom = createAction(
  '[Category] Categories sent to sync to Ecom'
);

export const syncCategoriesToEcomSuccess = createAction(
  '[Category] Categories synced to Ecom successfully'
);
